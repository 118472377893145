export default defineAppConfig({
  ui: {
    button: {
      default: {
        variant: "ghost",
      },
    },
    formGroup: {
      container: "mt-0 flex-1",
      label: {
        base: "block text-sm font-medium leading-6 text-[--color-dark-text]",
      },
    },
    table: {
      td: {
        padding: "px-3 py-4",
        color: "text-gray-600",
        size: "text-sm",
      },
      th: {
        base: "bg-gray-200/50",
        color: "text-gray-900 dark:text-white",
      },
    },
    verticalNavigation: {
      ring: "focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400",
      padding: "p-2",
      font: "font-medium",
      size: "text-base",
      active: "text-[--color-green-text] before:bg-green-500 before:opacity-5",
      icon: "text-[--color-green-text]",
      inactive:
        "text-[--color-dark-text] hover:text-[--color-green-text] hover:before:bg-green-700 hover:before:opacity-5",
    },
    pagination: {
      wrapper: "space-x-1 w-full",
      base: "px-4 text-gray-700 hover:bg-gray-100",
      rounded: "rounded-md",
      default: {
        activeButton: {
          class: "text-dark-500 bg-gray-100",
        },
        inactiveButton: {
          class: "bg-white",
        },
        prevButton: {
          icon: "i-heroicons-arrow-small-left-20-solid",
          label: "Previous",
          class: "mr-auto px-4 ring-1 ring-gray-200",
        },
        nextButton: {
          icon: "i-heroicons-arrow-small-right-20-solid",
          label: "Next",
          class: "!ml-auto px-4 ring-1 ring-gray-200",
        },
      },
    },
    tabs: {
      wrapper: "relative space-0 pb-1",
      container: "bg-white",
      list: {
        base: "relative",
        background: "bg-white dark:bg-gray-800 px-0",
        height: "h-8",
        width: "w-fit",
        tab: {
          base: "bg-white text-dark",
          active:
            "border-b-2 border-[--color-primary] [&>span]:text-[--color-primary] [&>span]:bg-green-200/50 [&>span]:border-green-200/50",
          rounded: "rounded-none",
        },
      },
    },
    toggle: {
      rounded: "h-6 w-12 sm:h-9 sm:w-16",
      "active": "bg-[#0EAD9C]",
      "container": {
        "base": "h-5 w-5 sm:w-8 sm:h-8",
        "active": "translate-x-6 rtl:-translate-x-6 sm:translate-x-7 sm:rtl:-translate-x-7"
      }
    },
    notifications: {
      position: 'm-auto bottom-auto top-0 left-0 right-0'
    },
    tooltip: {
      base: 'px-2 py-1 text-xs font-normal relative whitespace-normal h-fit',
      color: 'text-[--color-gray-text] dark:text-white',
    }
  },
});
