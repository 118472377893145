// Docs: https://www.npmjs.com/package/vue3-google-map
import { GoogleMap, CustomControl, Marker } from "vue3-google-map";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(GoogleMap)
  nuxtApp.vueApp.component('GoogleMap', GoogleMap)
  nuxtApp.vueApp.component('CustomControl', CustomControl)
  nuxtApp.vueApp.component('Marker', Marker)

  return {
    provide: {
      GoogleMap
    }
  }
})