export const appPageTransition = {"name":"page"}

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"preload","as":"font","href":"/assets/fonts/ralph/ralph-icons.eot","type":"font/eot","crossorigin":"anonymous"},{"rel":"preload","as":"font","href":"/assets/fonts/ralph/ralph-icons.woff","type":"font/woff","crossorigin":"anonymous"},{"rel":"preload","as":"font","href":"/assets/fonts/ralph/ralph-icons.ttf","type":"font/ttf","crossorigin":"anonymous"},{"rel":"preload","as":"font","href":"/assets/fonts/ralph/ralph-icons.svg","type":"image/svg+xml","crossorigin":"anonymous"}],"style":[],"script":[],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'