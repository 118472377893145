import {
  Chart,
  Title,
  Tooltip,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend,
} from "chart.js";
export default defineNuxtPlugin(() => {
  Chart.register(
    BarElement,
    Title,
    Tooltip,
    CategoryScale,
    LinearScale,
    Legend
  );
});
