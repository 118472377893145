// Retrieve cookie stored in browser
const csrfCookie = () => {
  const token: any = useCookie("XSRF-TOKEN");

  return token.value ?? undefined;
};

const retrieveCsrfCookie = async () => {
  const runtimeConfig = useRuntimeConfig();

  // If CSRF token doesn't exist, make request to API to retrieve it
  if (!csrfCookie()) {
    await useFetch(runtimeConfig.public.publicUrl + "/sanctum/csrf-cookie", {
      method: "get",
      credentials: "include",
    });
  }
};

// Helper function to get cookie value
const getCookie = (name: String | any) => {
  const value: any = `; ${document.cookie}`;
  const parts: any = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

// Helper function to set cookie value
const setCookie = (name: String | any, value: any, time: any = null, unit: String = 'days') => {
  if(time){
    const expirationDate: Date = new Date();
    
    if (unit === 'days') {
      expirationDate.setDate(expirationDate.getDate() + time);
    } else if (unit === 'minutes') {
        expirationDate.setTime(expirationDate.getTime() + time * 60000); // Convert minutes to milliseconds
    }

    const expires: String = `expires=${expirationDate.toUTCString()}`;

    return document.cookie = `${name}=${value}; ${expires}; path=/`;
  }

  return document.cookie = `${name}=${value}; path=/`;
};

// Helper function to delete cookie
const deleteCookie = (name: String | any) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export { csrfCookie, retrieveCsrfCookie, getCookie, setCookie, deleteCookie };
